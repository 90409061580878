(function(w, d) {
    function checkSwitches(form, switches) {
        var checkedCount = 0;
        for (var i=0; i<switches.length; i++) {
            if (switches[i].checked) checkedCount++;
        }
        if (checkedCount > 0) {
            form.classList.add('active');
        } else {
            form.classList.remove('active');
        }
    }
    
    function checkFormInput(input) {
        if (input.value.length > 0) {
            input.classList.add('filled');
        } else {
            input.classList.remove('filled');
        }
    }

    w.newsletter_subscribe_config.forEach(function(o){
        var block = d.getElementById('tncms-block-' + o.block_id);
        var form = block.querySelector('.newsletter-form');
        var popup = block.querySelector('.newsletter-form-popup');
        var inputs = block.querySelectorAll('.switch');

        block.addEventListener('change', function(e){
            if (e.target.classList.contains('switch')) {
                checkSwitches(popup, inputs);
            } else if (e.target.classList.contains('newsletter-form-input')) {
                checkFormInput(e.target);
            }
        });

        // Check inputs and switches on page load
        setTimeout(function() {
            var formInputs = block.querySelectorAll('.newsletter-form-input');
            for (var i = 0; i < formInputs.length; i++) {
                checkFormInput(formInputs[i]);
            }
            checkSwitches(form, inputs);
        }, 10);

        form.addEventListener('submit', function(e) {
            e.preventDefault();
            var successAlert = form.querySelector('.alert-danger');
            var errorAlert = form.querySelector('.alert-danger');
            successAlert.style.display = 'none';
            errorAlert.style.display = 'none';
            
            fetch(form.action, {
                body: new FormData(form),
                credentials: 'same-origin',
                method: 'POST'
            }).then(function(response) {
                if (response.status !== 200) {
                    console.log('Error. Status Code: ' + response.status);
                    return;
                }
                return response.text();
            }).then(function(text) {
                // Success
                if (text.indexOf('Thank You') !== -1) {
                    var successMessage = text.match(/<p>(.*?)<\/p>/);
                    if (successMessage) {
                        successAlert.querySelector('p').innerHTML = '<strong>Success!</strong> ' + successMessage[1];
                    }
                    successAlert.style.display = 'block';
                
                // Error
                } else {
                    var errorMessage = text.match(/<p>(.*?)<\/p>/);
                    if (errorMessage) {
                        errorAlert.querySelector('p').innerHTML = '<strong>Error!</strong> ' + errorMessage[1];
                    }
                    errorAlert.style.display = 'block';
                }
            }).catch(function(err) {
                console.log('Fetch Error: ', err);
            });
        });
    });
})(window, document);